<template>
  <div class="c-app flex-col">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8" lg="6" xl="5" sm="10" col="12">
          <CCardGroup>
            <CCard class="pt-5 pb-5 pl-3 pr-3">
              <img
                src="../../../public/logo/new_mei.png"
                style="width:50%;"
                class="justify-content-center center-logo"
              />
              <div class="d-flex mb-2">
                <span class="text-center right title">ME-Connect</span>
              </div>
              <div
                v-if="errorMessage != ''"
                class="mandatory-color error-font mt-2"
              >{{ errorMessage }}</div>
              <div>
                <ul v-if="validationMessage" class="text-danger mt-2">
                  <li
                    class="validation-message li-list-item"
                    v-for="item in validationMessage"
                    v-bind:key="item"
                  >{{ !Array.isArray(item) ? item : item[0] }}</li>
                </ul>
              </div>
              <CCardBody>
                <CForm novalidate>
                  <h1>Login</h1>
                  <CInput
                    placeholder="Email ID"
                    autocomplete="username email"
                    v-model.trim="email"
                    required
                    was-validated
                    @keyup.enter="submit()"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-user" />
                    </template>
                  </CInput>
                  <show-errors class="mb-4" :control="$v.email" :field="$t('app.generic.loginID')" />
                  <CInput
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                    required
                    was-validated
                    v-model="password"
                    @keyup.enter="submit()"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-lock-locked" />
                    </template>
                  </CInput>
                  <show-errors
                    class="mb-4"
                    :control="$v.password"
                    :field="$t('app.login.password')"
                  />
                  <CRow class="mb-2">
                    <CCol>
                      <div>
                        <vue-recaptcha
                          ref="recaptcha"
                          :sitekey="siteKey"
                          @verify="verifyMethod"
                          @expired="expiredMethod"
                          @render="renderMethod"
                          @error="errorMethod"
                          size="normal"
                        ></vue-recaptcha>
                      </div>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol col="4" class="text-left">
                      <CButton
                        color="primary"
                        class="px-2"
                        @click="submit()"
                        :disabled="!isCaptchaValidated"
                      >Login</CButton>
                    </CCol>
                    <CCol col="8" class="text-right">
                      <router-link to="/login/forgotpwd">
                        <CButton color="link" class="px-0">Forgot Password?</CButton>
                      </router-link>
                    </CCol>
                  </CRow>

                  <div class="d-flex mt-3">
                    <div class="text-center right">
                      <router-link to="/terms_of_use" target="_blank">
                        <CButton color="link" class="px-0 mr-2">Terms of Use</CButton>
                      </router-link>
                      <router-link to="/privacy_policy" target="_blank">
                        <CButton color="link" class="px-0">Privacy Policy</CButton>
                      </router-link>
                    </div>
                  </div>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { VueRecaptcha } from "vue-recaptcha";
import { required, maxLength } from "vuelidate/lib/validators";
import ShowErrors from "@/components/ShowErrors/ShowErrors.vue";
import { emailValidation } from "@/shared/utils";
import { SET_REGION } from "@/store/mutations.type";
import _ from "lodash";
import { LOGIN } from "@/store/actions.type";

export default {
  name: "LoginDetails",
  components: { ShowErrors, VueRecaptcha },
  data() {
    return {
      siteKey: process.env.VUE_APP_SITE_KEY,
      loginImage: `${process.env.BASE_URL}img/brand/logo.png`,
      email: "",
      password: "",
      errorMessage: "",
      validationMessage: "",
      disabled: false,
      isCaptchaValidated: false
    };
  },
  validations: {
    email: { required, emailValidation },
    password: {
      required
    }
  },
  methods: {
    verifyMethod(response) {
      this.isCaptchaValidated = true;
    },
    expiredMethod(x) {
      this.isCaptchaValidated = false;
      this.$refs.recaptcha.reset();
    },
    renderMethod() {},
    errorMethod() {
      this.$refs.recaptcha.reset();
    },
    /**
     * @method submit
     * @description It will validate the login credentials and based on that it will redirect to respective screen
     * @author @jeevan-poojary
     */
    submit() {
      console.log("1")
      this.$v.$touch();
      if (!this.$v.email.$invalid && !this.$v.password.$invalid) {
        this.$store
          .dispatch(LOGIN, {
            email: this.email,
            password: this.password,
            type: "admin"
          })
          .then(data => {
            this.$store.state.sidebarMinimize = false;
            if (_.get(data, "success", true)) {
              localStorage.setItem("businessUserName", this.email);
              localStorage.setItem("loginPassword", this.password);

              let regionName = _.get(data, "data.user", {});
              this.$store.commit(SET_REGION, regionName);
              this.$router.push({
                name: "users"
              });
            } else {
              if (_.get(data, "code", "200") == 422) {
                this.validationMessage = _.get(data, "message", "");
                this.errorMessage = "";
              } else {
                this.errorMessage = _.get(data, "message", "");
                this.validationMessage = "";
              }
            }
          });
      } else {
        return false;
      }
    }
  }
};
</script>
<style>
ul .li-list-item {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@supports not (-webkit-touch-callout: none) {
  /* CSS for other than iOS devices */
  .image-logo {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
    padding-top: 1rem !important;
    padding-bottom: 0.5rem !important;
  }
}

.right {
  text-align: center !important;
  width: 100% !important;
  float: right !important;
}

/* Safari 11+ */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .image-logo {
      padding-left: 2.5rem !important;
      padding-right: 2.5rem !important;
      padding-top: 0.5rem !important;
      padding-bottom: 0.25rem !important;
    }
  }
}

/* Test website on real Safari 11+ */

/* Safari 10.1 */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (not (stroke-color: transparent)) {
    .image-logo {
      padding-left: 2.5rem !important;
      padding-right: 2.5rem !important;
      padding-top: 0.5rem !important;
      padding-bottom: 0.25rem !important;
    }
  }
}

img.center-logo {
  display: block !important;
  margin: 0 auto !important;
}
</style>
